import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './BackgroundImage.scss';
import GatsbyImage from 'gatsby-image';

import { customPropTypes, useWindowSize } from '../../../util';
import { BREAKPOINTS } from '../../../util/motion-manager/constant';

function BackgroundImage({ data, dataMobile, underNav, underSubNav, hasIllustration }) {
  const windowSize = useWindowSize();

  const isMobile = windowSize.innerWidth <= BREAKPOINTS.medium;

  const imgStyle = hasIllustration
    ? {
        objectFit: isMobile ? 'cover' : 'contain',
        objectPosition: 'top center',
        backgroundSize: 'auto 100%;',
        height: isMobile ? 'auto' : '100%',
        width: isMobile ? '100%' : 'auto',
        left: '50%',
        transform: 'translateX(-50%)',
      }
    : {};

  return (
    <div
      className={classnames('BackgroundImage', {
        'has-mobile-image': dataMobile?.localFile,
        'under-primary-nav': underNav,
        'under-subnav': underSubNav,
        'has-illustration': hasIllustration,
      })}
    >
      {dataMobile?.localFile && (
        <GatsbyImage
          className="image mobile-image"
          fluid={dataMobile?.localFile.childImageSharp.fluid}
          aria-label={dataMobile?.altText}
          imgStyle={imgStyle}
          alt={dataMobile?.altText}
        />
      )}

      <GatsbyImage
        className="image desktop-image"
        fluid={data?.localFile?.childImageSharp.fluid}
        aria-label={data?.altText}
        imgStyle={imgStyle}
        alt={data?.altText}
      />
    </div>
  );
}

BackgroundImage.propTypes = checkExtraProps({
  data: customPropTypes.image.isRequired,
  dataMobile: customPropTypes.image,
  underNav: PropTypes.bool,
  underSubNav: PropTypes.bool,
  hasIllustration: PropTypes.bool,
});

BackgroundImage.defaultProps = {
  underNav: false,
  underSubNav: false,
  hasIllustration: false,
};

export default memo(BackgroundImage);
