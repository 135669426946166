import React, { memo, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';

import './Hero.scss';

import HTMLReactParser from 'html-react-parser';
import { graphql } from 'gatsby';
import { customPropTypes, useCSSMotion, useSectionTimeSpent } from '../../util';
import LogoTitle from './LogoTitle/LogoTitle';
import BackgroundImage from './BackgroundImage/BackgroundImage';
import JumpToLinks from '../JumpToLinks/JumpToLinks';
import { CTAButton, CTALink } from '../CTA';
import { ModalContext } from '../Layout/Layout';

function Hero({ data, jumpToLinks, pageContext }) {
  const {
    background,
    backgroundMobile,
    title,
    videoButtonText,
    subtitle,
    description,
    videoId,
    theme,
    hasAfterHero,
    name,
    jumpToLinkTitle,
    withIllustration,
    ctaLink,
  } = data;
  const { isBlog, isHome } = pageContext;

  const { setVideoId, setVideoPlayerOpen } = useContext(ModalContext);

  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);

  useSectionTimeSpent(name, containerRef);

  return (
    <section
      id={jumpToLinkTitle}
      className={classnames(
        'Hero',
        {
          'exclude-transitions': isBlog,
          'has-after-hero': hasAfterHero,
          'is-home': isHome,
          'is-blog': isBlog,
        },
        `theme-${theme}`,
        motionState,
      )}
      ref={containerRef}
    >
      <BackgroundImage
        hasIllustration={withIllustration}
        underNav={!isHome}
        underSubNav={isBlog}
        data={background}
        dataMobile={backgroundMobile}
      />

      <div
        className={classnames('container', {
          'under-subnav': isBlog,
        })}
      >
        {!isHome && jumpToLinks && <JumpToLinks hashLinks={jumpToLinks} motionState={motionState} />}

        <div className="content">
          <div className="sub-title">{subtitle}</div>

          {title &&
            (isHome ? (
              <LogoTitle title={title} motionState={motionState} />
            ) : (
              <h1 className="title">{title && HTMLReactParser(title)}</h1>
            ))}

          <div className="description">{description && HTMLReactParser(description)}</div>
          {ctaLink?.title && (
            <CTALink
              link={ctaLink?.url}
              target={ctaLink?.target}
              text={ctaLink?.title}
              largeText
              white
              icon="arrow"
              motionState={motionState}
            />
          )}
          {videoId && (
            <CTAButton
              onClick={() => {
                setVideoId(videoId);
                setVideoPlayerOpen(true);
              }}
              text={videoButtonText}
              white
              icon="play"
              motionState={motionState}
            />
          )}
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = checkExtraProps({
  data: PropTypes.shape({
    background: customPropTypes.image.isRequired,
    backgroundMobile: customPropTypes.image,
    description: PropTypes.string,
    linkButtonText: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    videoId: PropTypes.string,
    theme: PropTypes.oneOf(['empty', 'purple', 'green', 'dark-purple']),
    hasAfterHero: PropTypes.bool,
    withIllustration: PropTypes.bool,
    ...customPropTypes.basePropTypes,
  }).isRequired,
  jumpToLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      jumpToLinkTitle: PropTypes.string,
    }),
  ),
  pageContext: PropTypes.shape({
    isBlog: PropTypes.bool,
    isHome: PropTypes.bool,
  }),
});

export default memo(Hero);

export const fragments = graphql`
  fragment Hero on WpPage_Flexiblelayout_FlexibleChildren_Hero {
    background {
      ...HeroImage
    }
    backgroundMobile {
      ...HeroImageMobile
    }
    description
    hasAfterHero
    id
    jumpToLinkTitle
    videoButtonText: linkButtonText
    name
    subtitle
    theme
    title
    videoId
    withIllustration
    ctaLink {
      ...AcfLink
    }
  }

  fragment Hero_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_Hero {
    background {
      ...HeroImage
    }
    backgroundMobile {
      ...HeroImageMobile
    }
    description
    hasAfterHero
    id
    jumpToLinkTitle
    videoButtonText: linkButtonText
    name
    subtitle
    theme
    title
    videoId
    withIllustration
    ctaLink {
      ...AcfLink
    }
  }
`;
